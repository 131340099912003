import React, { useState } from 'react';
import NavBar from './NavBar';

export default function RouteNotFound() {
  return (
    <>
      <h1>404 Not Found</h1>
    </>
  );
}
