import React from 'react';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import AppBar from './Components/AppBar';

function App() {
  return (
    <div className="App">
      <AppBar />
    </div>
  );
}

export default App;
