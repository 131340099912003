import React, { useState } from 'react';
import Spectrum from './Spectrum';
import Oscillator from './Oscillator';

export default function Experiments() {
  return (
    <>
      <Oscillator />
    </>
  );
}
